@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  font-style: normal;
  src:
    local('Poppins'),
    url('/public/fonts/Poppins/Poppins-Thin.ttf') format('opentype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 200;
  font-style: normal;
  src:
    local('Poppins'),
    url('/public/fonts/Poppins/Poppins-ExtraLight.ttf') format('opentype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: normal;
  src:
    local('Poppins'),
    url('/public/fonts/Poppins/Poppins-Light.ttf') format('opentype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  src:
    local('Poppins'),
    url('/public/fonts/Poppins/Poppins-Regular.ttf') format('opentype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  src:
    local('Poppins'),
    url('/public/fonts/Poppins/Poppins-Medium.ttf') format('opentype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  src:
    local('Poppins'),
    url('/public/fonts/Poppins/Poppins-SemiBold.ttf') format('opentype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: normal;
  src:
    local('Poppins'),
    url('/public/fonts/Poppins/Poppins-Bold.ttf') format('opentype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  font-style: normal;
  src:
    local('Poppins'),
    url('/public/fonts/Poppins/Poppins-ExtraBold.ttf') format('opentype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  font-style: normal;
  src:
    local('Poppins'),
    url('/public/fonts/Poppins/Poppins-Black.ttf') format('opentype');
}

@font-face {
  font-family: 'BaseNeueTrial';
  src: url('/public/fonts/BaseNeueTrial-CondExtBd.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BaseNeueTrial';
  src: url('/public/fonts/BaseNeueTrial-CondExtLt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
